import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import android from "../images/android.svg"
import ios from "../images/apple-ios.svg"
const Mobile = () => {
  return (
    <Layout>
      <main id='main' style={{ backgroundColor: "rgb(36, 41, 67)" }}>
        <h2 className="text-center text-4xl my-16">
          Mobile Application Development
        </h2>
        <div className="grid grid-cols-3 grid-auto-rows px-16 md:px-32 lg:px-64">
          <div className="col-start-1 col-span-3 grid-row-1 md:col-span-2 row-start-1 my-16 pt-5">
            <h2 className="text-2xl font-bold pl-5">IOS</h2>
            <p className="text-xl p-5">
              Take your business to the next level. Improve customer loyalty,
              brand recognition and visibility by creating a direct marketing
              channel that will drive your profits up.
            </p>
          </div>

          <div className="col-start-1 col-span-3 md:col-span-1 row-start-2 md:row-start-1 my-16">
            <img
              src={ios}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>

          <div className="col-start-1 col-span-3 md:col-start-2 row-start-3 md:row-start-2 my-16">
            <h2 className="text-2xl font-bold pl-5">Android</h2>
            <p className="text-xl p-5">
              Using a technology called React Native, we are able to develop
              applications for Android as well as IOS with a unified code base,
              exposing your brand to even more users than before.
            </p>
          </div>
          <div className="col-start-1 col-span-3 md:col-span-1 row-start-4 md:row-start-2 my-16">
            <img
              src={android}
              style={{
                filter:
                  "invert(99%) sepia(68%) saturate(247%) hue-rotate(347deg) brightness(120%) contrast(100%)",
              }}
              className="opacity-75"
            />
          </div>
          {/* <div className="col-start-1 col-span-3 md:col-span-2 my-16">
            <h2 className="text-2xl">Product Development</h2>
            <p>
              We use a multi-disciplinary approach encompassing the following
              methods:
            </p>
            <div className="w-full flex ">
              <ol className="list-disc list-inside">
                <li>Agile Development</li>
                <li>Project Management</li>
                <li>Wire Framing</li>
                <li>User Stories</li>
                <li>Scoping</li>
                <li>Visual Design</li>
              </ol>
            </div>
          </div> */}
        </div>
      </main>
    </Layout>
  )
}

export default Mobile
